window.mv = window.mv || {};
window.mv.basket = window.mv.basket || {};

let basketModule = null;
window.mv.basket._module = async () => {
  if (!basketModule) {
    basketModule = import('../features/basket');
  }

  return basketModule;
};
